<template class="mr-3">
  <div class="card">
    <div class="card-body">
      <template>
        <div class="card-body">
          <a-button type="user-add" @click="showModal">
            <a-icon type="user-add" @click="showModal" />{{
              $t("action.ajouter")
            }}
          </a-button>
          <a-modal
            v-model="visible"
            :title="$t('menu.autorisation')"
            on-ok="handleOk"
            :width="900"
            :footer="false"
          >
            <a-form :form="form" @submit="addAccess" class="container">
              <div class="row">
                <div class="col-md-8 d-inline">
                  <a-form-item :label="$t('autorisation.designation')">
                    <a-input
                      v-decorator="[
                        `role`,
                        {
                          rules: [
                            {
                              required: true,
                              message: $t('requis.designation'),
                            },
                          ],
                        },
                      ]"
                    />
                  </a-form-item>
                </div>
                <div class="col-md-4 d-inline mt-4">
                  <i class="fa fa-universal-access mr-2" />{{
                    $t("autorisation.admin")
                  }}
                  <a-form-item>
                    <a-switch
                      @click="handleAdminChecked"
                      class="mt-2"
                      v-model="checkedKeys.all"
                    >
                      <a-icon slot="checkedChildren" type="check" />
                      <a-icon slot="unCheckedChildren" type="close" />
                    </a-switch>
                  </a-form-item>
                </div>
              </div>
              <div class="row">
                <a-divider>{{ $t("menu.generale") }}</a-divider>

                <!--                 <div
                  class="col-md-3"
                  v-if="verifyEdManagerAccess(['statstiqueGeneral'])"
                >
                  <i class="fa fa-home mr-2" /> {{ $t("menu.accueil") }}
                  <a-form-item>
                    <a-switch v-model="checkedKeys.home">
                      <a-icon slot="checkedChildren" type="check" />
                      <a-icon slot="unCheckedChildren" type="close" />
                    </a-switch>
                  </a-form-item>
                </div> -->
                <div class="col-md-3" v-if="verifyEdManagerAccess(['news'])">
                  <i class="fa fa-newspaper-o mr-2" /> {{ $t("menu.actulite") }}
                  <a-form-item>
                    <a-switch v-model="checkedKeys.actualite">
                      <a-icon slot="checkedChildren" type="check" />
                      <a-icon slot="unCheckedChildren" type="close" />
                    </a-switch>
                  </a-form-item>
                </div>
                <div
                  class="col-md-3"
                  v-if="verifyEdManagerAccess(['calender'])"
                >
                  <i class="fa fa-calendar mr-2" /> {{ $t("menu.calender") }}
                  <a-form-item>
                    <a-switch v-model="checkedKeys.calendar">
                      <a-icon slot="checkedChildren" type="check" />
                      <a-icon slot="unCheckedChildren" type="close" />
                    </a-switch>
                  </a-form-item>
                </div>
              </div>
              <div class="row">
                <div class="col-md-3" v-if="verifyEdManagerAccess(['chat'])">
                  <a-form-item>
                    <a-tree
                      v-model="checkedKeys.chat"
                      checkable
                      :default-expand-all="true"
                      @select="onSelect"
                    >
                      <a-tree-node key="all" :title="$t('menu.chat')">
                        <a-tree-node key="chat" :title="$t('menu.chat')" />
                        <a-tree-node
                          key="parent_details"
                          :title="$t('chat.parentDetails')"
                        />
                        <a-tree-node
                          key="payment_status"
                          :title="$t('paymentStatus.paymentStatus')"
                        />
                      </a-tree-node>
                    </a-tree>
                  </a-form-item>
                </div>
                <div class="col-md-3" v-if="verifyEdManagerAccess(['ptchat'])">
                  <a-form-item>
                    <a-tree
                      v-model="checkedKeys.supervision"
                      checkable
                      :default-expand-all="true"
                      @select="onSelect"
                    >
                      <a-tree-node key="all" :title="$t('menu.supervision')">
                        <a-tree-node key="ptchat" :title="$t('menu.ptchat')" />
                      </a-tree-node>
                    </a-tree>
                  </a-form-item>
                </div>
                <div class="col-md-3" v-if="verifyEdManagerAccess(['sms'])">
                  <a-form-item>
                    <a-tree
                      v-model="checkedKeys.sms"
                      checkable
                      :default-expand-all="true"
                      @select="onSelect"
                    >
                      <a-tree-node key="all" :title="$t('menu.sms')">
                        <a-tree-node
                          key="send"
                          :title="$t('menu.envoyerSms')"
                        />
                        <a-tree-node
                          key="history"
                          :title="$t('menu.historiqueSms')"
                        />
                      </a-tree-node>
                    </a-tree>
                  </a-form-item>
                </div>
                <div
                  class="col-md-3"
                  v-if="verifyEdManagerAccess(['statstiqueGeneral'])"
                >
                  <a-form-item>
                    <a-tree
                      v-model="checkedKeys.home"
                      checkable
                      :default-expand-all="true"
                      @select="onSelect"
                    >
                      <a-tree-node key="all" :title="$t('menu.statistique')">
                        <a-tree-node
                          key="statstiqueGeneral"
                          :title="$t('menu.statstiqueGeneral')"
                        />
                        <a-tree-node
                          v-if="verifyEdManagerAccess(['usersStats'])"
                          key="usersStats"
                          :title="$t('stats.connectedStats')"
                        />
                      </a-tree-node>
                    </a-tree>
                  </a-form-item>
                </div>
              </div>
              <div class="row">
                <a-divider>{{ $t("autorisation.parametrage") }}</a-divider>

                <div class="col-md-6">
                  <a-form-item>
                    <a-tree
                      v-model="checkedKeys.parametrage"
                      checkable
                      :default-expand-all="true"
                      @select="onSelect"
                    >
                      <a-tree-node
                        key="all"
                        :title="$t('autorisation.parametrage')"
                      >
                        <a-tree-node
                          key="etablissement"
                          :title="$t('menu.etablissement')"
                          v-if="
                            verifyEdManagerAccess(['parametrageEtablissement'])
                          "
                        />
                        <a-tree-node
                          key="comptabilite"
                          :title="$t('menu.comptabilite')"
                          v-if="
                            verifyEdManagerAccess(['parametrageComptabilite'])
                          "
                        />
                        <a-tree-node
                          key="scolarite"
                          :title="$t('menu.scolarite')"
                          v-if="verifyEdManagerAccess(['parametrageScolarite'])"
                        />
                        <a-tree-node
                          key="bulletin"
                          :title="$t('menu.bulletin')"
                          v-if="verifyEdManagerAccess(['bulletin'])"
                        />
                        <a-tree-node key="sms" :title="$t('menu.sms')" />
                        <a-tree-node
                          v-if="verifyEdManagerAccess(['smartSchedule'])"
                          key="smart_schedule"
                          :title="$t('menu.smartSchedule')"
                        />
                      </a-tree-node>
                    </a-tree>
                  </a-form-item>
                </div>
                <div
                  class="col-md-6"
                  v-if="verifyEdManagerAccess(['adminstration'])"
                >
                  <a-form-item>
                    <a-tree
                      v-model="checkedKeys.adminstration"
                      checkable
                      :default-expand-all="true"
                      @select="onSelect"
                    >
                      <a-tree-node key="all" :title="$t('menu.administration')">
                        <i slot="icon" class="fa fa-expeditedssl" />
                        <a-tree-node
                          key="autorisation"
                          :title="$t('menu.autorisation')"
                        />
                        <a-tree-node
                          key="utilisateur"
                          :title="$t('menu.utilisateur')"
                        />
                      </a-tree-node>
                    </a-tree>
                  </a-form-item>
                </div>
                <a-divider>{{ $t("menu.scolarite") }}</a-divider>

                <div
                  class="col-md-3"
                  v-if="verifyEdManagerAccess(['studentList'])"
                >
                  <i class="fa fa-database mr-2" />{{ $t("menu.listeEleves") }}
                  <a-form-item>
                    <!-- <a-switch v-model="checkedKeys.liste_eleves">
                      <a-icon slot="checkedChildren" type="check" />
                      <a-icon slot="unCheckedChildren" type="close" />
                    </a-switch> -->
                    <a-tree
                      v-model="checkedKeys.liste_eleves"
                      checkable
                      :default-expand-all="true"
                      @select="onSelect"
                    >
                      <a-tree-node key="all" :title="$t('menu.listeEleves')">
                        <a-tree-node
                          key="liste_eleves"
                          :title="$t('menu.listeEleves')"
                        />
                        <a-tree-node
                          key="annulation_eleve"
                          :title="$t('profil.annulationEleve')"
                        />
                        <a-tree-node
                          key="suppression_eleve"
                          :title="$t('profil.deleteEleve')"
                        />
                        <a-tree-node
                          key="profil_information"
                          :title="$t('profil.informations')"
                        />
                        <a-tree-node
                          key="paiement_scolarite"
                          :title="$t('profil.paiementScol')"
                        />
                        <a-tree-node
                          key="paiement_activite"
                          :title="$t('profil.paiementAct')"
                        />
                        <a-tree-node
                          key="liste_reglement"
                          :title="$t('profil.listeReglements')"
                        />
                        <a-tree-node
                          key="qr_code"
                          :title="$t('profil.qrCode')"
                        />
                        <a-tree-node
                          key="bulletin"
                          :title="$t('bulletin.bulletin')"
                        />
                        <a-tree-node
                          key="assiduite"
                          :title="$t('menu.assiduite')"
                        />
                      </a-tree-node>
                    </a-tree>
                  </a-form-item>
                </div>
                <div
                  class="col-md-3"
                  v-if="verifyEdManagerAccess(['bulletin'])"
                >
                  <i class="fa fa-file-text mr-2" />{{
                    $t("bulletin.bulletin")
                  }}
                  <a-form-item>
                    <!-- <a-switch v-model="checkedKeys.bulletin">
                      <a-icon slot="checkedChildren" type="check" />
                      <a-icon slot="unCheckedChildren" type="close" />
                    </a-switch> -->
                    <a-tree
                      v-model="checkedKeys.bulletin"
                      checkable
                      :default-expand-all="true"
                      @select="onSelect"
                    >
                      <a-tree-node key="all" :title="$t('menu.bulletin')">
                        <a-tree-node
                          key="pedagogic"
                          :title="$t('bulletin.bulletinPedagogique')"
                        />
                        <a-tree-node
                          key="pilote"
                          :title="$t('bulletin.bulletinSpecifique')"
                        />
                        <a-tree-node
                          key="specific"
                          :title="$t('bulletin.bulletinVerySpecifique')"
                        />
                        <a-tree-node
                          key="statistique"
                          :title="$t('menu.bulletinStatistique')"
                        />
                      </a-tree-node>
                    </a-tree>
                  </a-form-item>
                </div>
                <div
                  class="col-md-3"
                  v-if="verifyEdManagerAccess(['attendance'])"
                >
                  <i class="fa fa-th-list mr-2" />{{ $t("menu.assiduite") }}
                  <a-form-item>
                    <a-switch v-model="checkedKeys.assiduite">
                      <a-icon slot="checkedChildren" type="check" />
                      <a-icon slot="unCheckedChildren" type="close" />
                    </a-switch>
                  </a-form-item>
                </div>
                <div
                  class="col-md-3"
                  v-if="verifyEdManagerAccess(['activites', 'cantine'])"
                >
                  <a-form-item>
                    <a-tree
                      v-model="checkedKeys.activite"
                      checkable
                      :default-expand-all="true"
                      @select="onSelect"
                    >
                      <a-tree-node
                        key="all"
                        :title="$t('autorisation.activite')"
                        v-if="verifyEdManagerAccess(['activites'])"
                      >
                        <a-tree-node
                          key="inscription"
                          :title="$t('liste.inscription')"
                          v-if="verifyEdManagerAccess(['activites'])"
                        />

                        <a-tree-node
                          key="etat_paiement"
                          :title="$t('menu.etatPaiements')"
                          v-if="verifyEdManagerAccess(['activites'])"
                        />
                        <a-tree-node
                          key="cantine"
                          v-if="verifyEdManagerAccess(['cantine'])"
                          :title="$t('cantine.cantine')"
                        />
                      </a-tree-node>
                    </a-tree>
                  </a-form-item>
                </div>
                <div
                  class="col-md-3"
                  v-if="verifyEdManagerAccess(['scolarite', 'punishments'])"
                >
                  <a-form-item>
                    <a-tree
                      v-model="checkedKeys.scolarite"
                      checkable
                      :default-expand-all="true"
                      @select="onSelect"
                    >
                      <a-tree-node key="all" :title="$t('menu.scolarite')">
                        <a-tree-node
                          key="inscription"
                          :title="$t('liste.inscription')"
                        />
                        <a-tree-node
                          key="etat_paiement"
                          :title="$t('menu.etatPaiements')"
                        />
                        <a-tree-node
                          key="punition"
                          v-if="verifyEdManagerAccess(['punishments'])"
                          :title="$t('punition.punition')"
                        />
                      </a-tree-node>
                    </a-tree>
                  </a-form-item>
                </div>
                <div
                  class="col-md-3"
                  v-if="verifyEdManagerAccess(['personnel'])"
                >
                  <a-form-item>
                    <a-tree
                      v-model="checkedKeys.personnel"
                      checkable
                      :default-expand-all="true"
                      @select="onSelect"
                    >
                      <a-tree-node key="all" :title="$t('menu.personnel')">
                        <a-tree-node
                          key="personnel"
                          :title="$t('menu.personnel')"
                        />
                        <a-tree-node
                          key="enseignant"
                          :title="$t('emploi.enseignant')"
                        />
                        <a-tree-node
                          key="etat_paiement"
                          :title="$t('menu.etatPaiements')"
                        />
                        <a-tree-node
                          key="viremenet_bancaire"
                          :title="$t('menu.virementBancaire')"
                        />
                      </a-tree-node>
                    </a-tree>
                  </a-form-item>
                </div>
                <div
                  class="col-md-3"
                  v-if="verifyEdManagerAccess(['emplois', 'smartSchedule'])"
                >
                  <a-form-item>
                    <a-tree
                      v-model="checkedKeys.emplois"
                      checkable
                      :default-expand-all="true"
                      @select="onSelect"
                    >
                      <a-tree-node key="all" :title="$t('menu.emploi')">
                        <a-tree-node
                          key="eleves"
                          :title="$t('actualite.eleves')"
                          v-if="verifyEdManagerAccess(['emplois'])"
                        />
                        <a-tree-node
                          key="enseignant"
                          :title="$t('emploi.enseignant')"
                          v-if="verifyEdManagerAccess(['emplois'])"
                        />
                        <a-tree-node
                          v-if="verifyEdManagerAccess(['smartSchedule'])"
                          key="smart_schedules"
                          :title="$t('smartSchedule.smartSchedules')"
                        />
                        <a-tree-node
                          v-if="
                            verifyEdManagerAccess([
                              'smartSchedule',
                              'teacherAvailability',
                            ])
                          "
                          key="teacher_dispo"
                          :title="$t('menu.teacherAvailability')"
                        />
                      </a-tree-node>
                    </a-tree>
                  </a-form-item>
                </div>
                <a-divider>{{ $t("comptabilite.comptabilite") }}</a-divider>
                <div
                  class="col-md-6"
                  v-if="verifyEdManagerAccess(['comptabilite'])"
                >
                  <i class="fe fe-dollar-sign" />{{
                    $t("comptabilite.comptabilite")
                  }}
                  <a-form-item>
                    <a-tree
                      v-model="checkedKeys.comptabilite"
                      checkable
                      :default-expand-all="true"
                      @select="onSelect"
                    >
                      <a-tree-node
                        key="all"
                        :title="$t('comptabilite.comptabilite')"
                      >
                        <a-tree-node
                          key="etat_recettes"
                          :title="$t('menu.etatRecettes')"
                        />
                        <a-tree-node
                          key="avance"
                          :title="$t('menu.caisseAvance')"
                        />
                        <a-tree-node
                          key="journale_caisse"
                          :title="$t('menu.journaleCaisse')"
                        />
                        <a-tree-node
                          key="depenses"
                          :title="$t('menu.depenses')"
                        />

                        <a-tree-node
                          key="etat_impayes"
                          :title="$t('menu.etatImpayes')"
                        />

                        <a-tree-node
                          key="liste_reglement"
                          :title="$t('menu.listeReglement')"
                        />
                        <a-tree-node
                          key="reglement_annule"
                          :title="$t('menu.reglementsAnnules')"
                        />
                      </a-tree-node>
                    </a-tree>
                  </a-form-item>
                </div>
                <div
                  class="col-md-4"
                  v-if="verifyEdManagerAccess(['declaration'])"
                >
                  <i class="fe fe-layers" />{{ $t("menu.declaration") }}
                  <a-form-item>
                    <a-tree
                      v-model="checkedKeys.declaration"
                      checkable
                      :default-expand-all="true"
                      @select="onSelect"
                    >
                      <a-tree-node key="all" :title="$t('menu.declaration')">
                        <a-tree-node
                          key="etat_impayesA"
                          :title="$t('menu.etatImpayesActivites')"
                        />
                        <a-tree-node
                          key="etat_impayesS"
                          :title="$t('menu.etatImpayesScolarite')"
                        />
                        <a-tree-node
                          key="declarationTVA"
                          :title="$t('menu.declarationTva')"
                        />

                        <a-tree-node
                          key="chiffre_affaire"
                          :title="$t('menu.chiffreAffaires')"
                        />

                        <a-tree-node
                          key="chiffre_affaireS"
                          :title="$t('menu.chiffreAffairesScolarite')"
                        />
                      </a-tree-node>
                    </a-tree>
                  </a-form-item>
                </div>
              </div>
              <div class="form-actions row">
                <div class="col-md-2 offset-md-7">
                  <a-button
                    key="back"
                    @click="handleCancel"
                    :disabled="loadingAddAccess"
                  >
                    {{ $t("action.annuler") }}
                  </a-button>
                </div>
                <div class="col-md-2 offset-md-1">
                  <a-button
                    type="primary"
                    htmlType="submit"
                    :loading="loadingAddAccess"
                    :disabled="loadingAddAccess"
                  >
                    {{ $t("action.ajouter") }}
                  </a-button>
                </div>
              </div>
            </a-form>
          </a-modal>
          <a-modal
            v-model="updateVisible"
            title="Autorisation "
            on-ok="handleOk"
            :width="900"
            :footer="false"
          >
            <a-form :form="updateForm" @submit="updateAccess" class="container">
              <div class="row">
                <div class="col-md-4 d-inline mt-4">
                  <i class="fa fa-universal-access mr-2" />{{
                    $t("autorisation.admin")
                  }}
                  <a-form-item>
                    <a-switch
                      @click="handleAdminCheckedUpdate"
                      class="mt-2"
                      v-model="updateData.access.all"
                    >
                      <a-icon slot="checkedChildren" type="check" />
                      <a-icon slot="unCheckedChildren" type="close" />
                    </a-switch>
                  </a-form-item>
                </div>
              </div>
              <div class="row">
                <a-divider>{{ $t("menu.generale") }}</a-divider>

                <!--                 <div
                  class="col-md-3"
                  v-if="verifyEdManagerAccess(['statstiqueGeneral'])"
                >
                  <i class="fa fa-home mr-2" /> {{ $t("menu.accueil") }}
                  <a-form-item>
                    <a-switch v-model="updateData.access.home">
                      <a-icon slot="checkedChildren" type="check" />
                      <a-icon slot="unCheckedChildren" type="close" />
                    </a-switch>
                  </a-form-item>
                </div> -->
                <div class="col-md-3" v-if="verifyEdManagerAccess(['news'])">
                  <i class="fa fa-newspaper-o mr-2" /> {{ $t("menu.actulite") }}
                  <a-form-item>
                    <a-switch v-model="updateData.access.actualite">
                      <a-icon slot="checkedChildren" type="check" />
                      <a-icon slot="unCheckedChildren" type="close" />
                    </a-switch>
                  </a-form-item>
                </div>
                <div
                  class="col-md-3"
                  v-if="verifyEdManagerAccess(['calender'])"
                >
                  <i class="fa fa-calendar mr-2" /> {{ $t("menu.calender") }}
                  <a-form-item>
                    <a-switch v-model="updateData.access.calendar">
                      <a-icon slot="checkedChildren" type="check" />
                      <a-icon slot="unCheckedChildren" type="close" />
                    </a-switch>
                  </a-form-item>
                </div>
              </div>
              <div class="row">
                <div class="col-md-3" v-if="verifyEdManagerAccess(['chat'])">
                  <a-form-item>
                    <a-tree
                      v-model="updateData.access.chat"
                      checkable
                      :default-expand-all="true"
                      @select="onSelect"
                    >
                      <a-tree-node key="all" :title="$t('menu.chat')">
                        <a-tree-node key="chat" :title="$t('menu.chat')" />
                        <a-tree-node
                          key="parent_details"
                          :title="$t('chat.parentDetails')"
                        />
                        <a-tree-node
                          key="payment_status"
                          :title="$t('paymentStatus.paymentStatus')"
                        />
                      </a-tree-node>
                    </a-tree>
                  </a-form-item>
                </div>
                <div class="col-md-3" v-if="verifyEdManagerAccess(['ptchat'])">
                  <a-form-item>
                    <a-tree
                      v-model="updateData.access.supervision"
                      checkable
                      :default-expand-all="true"
                      @select="onSelect"
                    >
                      <a-tree-node key="all" :title="$t('menu.supervision')">
                        <a-tree-node key="ptchat" :title="$t('menu.ptchat')" />
                      </a-tree-node>
                    </a-tree>
                  </a-form-item>
                </div>
                <div class="col-md-3" v-if="verifyEdManagerAccess(['sms'])">
                  <a-form-item>
                    <a-tree
                      v-model="updateData.access.sms"
                      checkable
                      :default-expand-all="true"
                      @select="onSelect"
                    >
                      <a-tree-node key="all" :title="$t('menu.sms')">
                        <a-tree-node
                          key="send"
                          :title="$t('menu.envoyerSms')"
                        />
                        <a-tree-node
                          key="history"
                          :title="$t('menu.historiqueSms')"
                        />
                      </a-tree-node>
                    </a-tree>
                  </a-form-item>
                </div>
                <div
                  class="col-md-3"
                  v-if="verifyEdManagerAccess(['statstiqueGeneral'])"
                >
                  <a-form-item>
                    <a-tree
                      v-model="updateData.access.home"
                      checkable
                      :default-expand-all="true"
                      @select="onSelect"
                    >
                      <a-tree-node key="all" :title="$t('menu.statistique')">
                        <a-tree-node
                          key="statstiqueGeneral"
                          :title="$t('menu.statstiqueGeneral')"
                        />
                        <a-tree-node
                          v-if="verifyEdManagerAccess(['usersStats'])"
                          key="usersStats"
                          :title="$t('stats.connectedStats')"
                        />
                      </a-tree-node>
                    </a-tree>
                  </a-form-item>
                </div>
              </div>
              <div class="row">
                <a-divider>{{ $t("autorisation.parametrage") }}</a-divider>

                <div class="col-md-6">
                  <a-form-item>
                    <a-tree
                      v-model="updateData.access.parametrage"
                      checkable
                      :default-expand-all="true"
                      @select="onSelect"
                    >
                      <a-tree-node
                        key="all"
                        :title="$t('autorisation.parametrage')"
                      >
                        <a-tree-node
                          key="etablissement"
                          :title="$t('menu.etablissement')"
                          v-if="
                            verifyEdManagerAccess(['parametrageEtablissement'])
                          "
                        />
                        <a-tree-node
                          key="comptabilite"
                          :title="$t('comptabilite.comptabilite')"
                          v-if="
                            verifyEdManagerAccess(['parametrageComptabilite'])
                          "
                        />
                        <a-tree-node
                          key="scolarite"
                          :title="$t('menu.scolarite')"
                          v-if="verifyEdManagerAccess(['parametrageScolarite'])"
                        />
                        <a-tree-node
                          key="bulletin"
                          :title="$t('menu.bulletin')"
                          v-if="verifyEdManagerAccess(['bulletin'])"
                        />
                        <a-tree-node
                          key="sms"
                          :title="$t('menu.sms')"
                          v-if="verifyEdManagerAccess(['sms'])"
                        />
                        <a-tree-node
                          v-if="verifyEdManagerAccess(['smartSchedule'])"
                          key="smart_schedule"
                          :title="$t('menu.smartSchedule')"
                        />
                      </a-tree-node>
                    </a-tree>
                  </a-form-item>
                </div>
                <div
                  class="col-md-6"
                  v-if="verifyEdManagerAccess(['adminstration'])"
                >
                  <a-form-item>
                    <a-tree
                      v-model="updateData.access.adminstration"
                      checkable
                      :default-expand-all="true"
                      @select="onSelect"
                    >
                      <a-tree-node key="all" :title="$t('menu.administration')">
                        <i slot="icon" class="fa fa-expeditedssl" />
                        <a-tree-node
                          key="autorisation"
                          :title="$t('menu.autorisation')"
                        />
                        <a-tree-node
                          key="utilisateur"
                          :title="$t('menu.utilisateur')"
                        />
                      </a-tree-node>
                    </a-tree>
                  </a-form-item>
                </div>
                <a-divider>{{ $t("menu.scolarite") }}</a-divider>

                <div class="col-md-3">
                  <i class="fa fa-database mr-2" />{{ $t("menu.listeEleves") }}
                  <a-form-item>
                    <!-- <a-switch v-model="updateData.access.liste_eleves">
                      <a-icon slot="checkedChildren" type="check" />
                      <a-icon slot="unCheckedChildren" type="close" />
                    </a-switch> -->
                    <a-tree
                      v-model="updateData.access.liste_eleves"
                      checkable
                      :default-expand-all="true"
                      @select="onSelect"
                    >
                      <a-tree-node
                        key="all"
                        :title="$t('menu.listeEleves')"
                        v-if="verifyEdManagerAccess(['studentList'])"
                      >
                        <a-tree-node
                          key="liste_eleves"
                          :title="$t('menu.listeEleves')"
                        />
                        <a-tree-node
                          key="annulation_eleve"
                          :title="$t('profil.annulationEleve')"
                        />
                        <a-tree-node
                          key="suppression_eleve"
                          :title="$t('profil.deleteEleve')"
                        />
                        <a-tree-node
                          key="profil_information"
                          :title="$t('profil.informations')"
                        />
                        <a-tree-node
                          key="paiement_scolarite"
                          :title="$t('profil.paiementScol')"
                        />
                        <a-tree-node
                          key="paiement_activite"
                          :title="$t('profil.paiementAct')"
                        />
                        <a-tree-node
                          key="liste_reglement"
                          :title="$t('profil.listeReglements')"
                        />
                        <a-tree-node
                          key="qr_code"
                          :title="$t('profil.qrCode')"
                        />
                        <a-tree-node
                          key="bulletin"
                          :title="$t('bulletin.bulletin')"
                        />
                        <a-tree-node
                          key="assiduite"
                          :title="$t('menu.assiduite')"
                        />
                      </a-tree-node>
                    </a-tree>
                  </a-form-item>
                </div>
                <div
                  class="col-md-3"
                  v-if="verifyEdManagerAccess(['bulletin'])"
                >
                  <i class="fa fa-file-text mr-2" />{{
                    $t("bulletin.bulletin")
                  }}
                  <a-form-item>
                    <!-- <a-switch v-model="updateData.access.bulletin">
                      <a-icon slot="checkedChildren" type="check" />
                      <a-icon slot="unCheckedChildren" type="close" />
                    </a-switch> -->
                    <a-tree
                      v-model="updateData.access.bulletin"
                      checkable
                      :default-expand-all="true"
                      @select="onSelect"
                    >
                      <a-tree-node key="all" :title="$t('menu.bulletin')">
                        <a-tree-node
                          key="pedagogic"
                          :title="$t('bulletin.bulletinPedagogique')"
                        />
                        <a-tree-node
                          key="pilote"
                          :title="$t('bulletin.bulletinSpecifique')"
                        />
                        <a-tree-node
                          key="specific"
                          :title="$t('bulletin.bulletinVerySpecifique')"
                        />
                        <a-tree-node
                          key="statistique"
                          :title="$t('menu.bulletinStatistique')"
                        />
                      </a-tree-node>
                    </a-tree>
                  </a-form-item>
                </div>
                <div
                  class="col-md-3"
                  v-if="verifyEdManagerAccess(['attendance'])"
                >
                  <i class="fa fa-th-list mr-2" />{{ $t("menu.assiduite") }}
                  <a-form-item>
                    <a-switch v-model="updateData.access.assiduite">
                      <a-icon slot="checkedChildren" type="check" />
                      <a-icon slot="unCheckedChildren" type="close" />
                    </a-switch>
                  </a-form-item>
                </div>
                <div
                  class="col-md-3"
                  v-if="verifyEdManagerAccess(['activites', 'cantine'])"
                >
                  <a-form-item>
                    <a-tree
                      v-model="updateData.access.activite"
                      checkable
                      :default-expand-all="true"
                      @select="onSelect"
                    >
                      <a-tree-node
                        key="all"
                        :title="$t('autorisation.activite')"
                        v-if="verifyEdManagerAccess(['activites'])"
                      >
                        <a-tree-node
                          key="inscription"
                          :title="$t('liste.inscription')"
                          v-if="verifyEdManagerAccess(['activites'])"
                        />

                        <a-tree-node
                          key="etat_paiement"
                          :title="$t('menu.etatPaiements')"
                          v-if="verifyEdManagerAccess(['activites'])"
                        />
                        <a-tree-node
                          key="cantine"
                          :title="$t('cantine.cantine')"
                          v-if="verifyEdManagerAccess(['cantine'])"
                        />
                      </a-tree-node>
                    </a-tree>
                  </a-form-item>
                </div>
                <div
                  class="col-md-3"
                  v-if="verifyEdManagerAccess(['scolarite', 'punishments'])"
                >
                  <a-form-item>
                    <a-tree
                      v-model="updateData.access.scolarite"
                      checkable
                      :default-expand-all="true"
                      @select="onSelect"
                    >
                      <a-tree-node key="all" :title="$t('menu.scolarite')">
                        <a-tree-node
                          key="inscription"
                          :title="$t('liste.inscription')"
                        />
                        <a-tree-node
                          key="etat_paiement"
                          :title="$t('menu.etatPaiements')"
                        />
                        <a-tree-node
                          key="punition"
                          v-if="verifyEdManagerAccess(['punishments'])"
                          :title="$t('punition.punition')"
                        />
                      </a-tree-node>
                    </a-tree>
                  </a-form-item>
                </div>
                <div
                  class="col-md-3"
                  v-if="verifyEdManagerAccess(['personnel'])"
                >
                  <a-form-item>
                    <a-tree
                      v-model="updateData.access.personnel"
                      checkable
                      :default-expand-all="true"
                      @select="onSelect"
                    >
                      <a-tree-node key="all" :title="$t('menu.personnel')">
                        <a-tree-node
                          key="personnel"
                          :title="$t('menu.personnel')"
                        />
                        <a-tree-node
                          key="enseignant"
                          :title="$t('emploi.enseignant')"
                        />
                        <a-tree-node
                          key="etat_paiement"
                          :title="$t('menu.etatPaiements')"
                        />
                        <a-tree-node
                          key="viremenet_bancaire"
                          :title="$t('menu.virementBancaire')"
                        />
                      </a-tree-node>
                    </a-tree>
                  </a-form-item>
                </div>
                <div
                  class="col-md-3"
                  v-if="verifyEdManagerAccess(['emplois', 'smartSchedule'])"
                >
                  <a-form-item>
                    <a-tree
                      v-model="updateData.access.emplois"
                      checkable
                      :default-expand-all="true"
                      @select="onSelect"
                    >
                      <a-tree-node key="all" :title="$t('menu.emploi')">
                        <a-tree-node
                          key="eleves"
                          :title="$t('actualite.eleves')"
                          v-if="verifyEdManagerAccess(['emplois'])"
                        />
                        <a-tree-node
                          key="enseignant"
                          :title="$t('emploi.enseignant')"
                          v-if="verifyEdManagerAccess(['emplois'])"
                        />
                        <a-tree-node
                          v-if="verifyEdManagerAccess(['smartSchedule'])"
                          key="smart_schedules"
                          :title="$t('smartSchedule.smartSchedules')"
                        />
                        <a-tree-node
                          v-if="verifyEdManagerAccess(['smartSchedule'])"
                          key="teacher_dispo"
                          :title="$t('menu.teacherAvailability')"
                        />
                      </a-tree-node>
                    </a-tree>
                  </a-form-item>
                </div>
                <a-divider>{{ $t("comptabilite.comptabilite") }}</a-divider>
                <div
                  class="col-md-6"
                  v-if="verifyEdManagerAccess(['comptabilite'])"
                >
                  <i class="fe fe-dollar-sign" />{{
                    $t("comptabilite.comptabilite")
                  }}
                  <a-form-item>
                    <a-tree
                      v-model="updateData.access.comptabilite"
                      checkable
                      :default-expand-all="true"
                      @select="onSelect"
                    >
                      <a-tree-node
                        key="all"
                        :title="$t('comptabilite.comptabilite')"
                      >
                        <a-tree-node
                          key="etat_recettes"
                          :title="$t('menu.etatRecettes')"
                        />
                        <a-tree-node
                          key="avance"
                          :title="$t('menu.caisseAvance')"
                        />
                        <a-tree-node
                          key="journale_caisse"
                          :title="$t('menu.journaleCaisse')"
                        />
                        <a-tree-node
                          key="depenses"
                          :title="$t('menu.depenses')"
                        />

                        <a-tree-node
                          key="etat_impayes"
                          :title="$t('menu.etatImpayes')"
                        />

                        <a-tree-node
                          key="liste_reglement"
                          :title="$t('menu.listeReglement')"
                        />
                        <a-tree-node
                          key="reglement_annule"
                          :title="$t('menu.reglementsAnnules')"
                        />
                      </a-tree-node>
                    </a-tree>
                  </a-form-item>
                </div>
                <div
                  class="col-md-4"
                  v-if="verifyEdManagerAccess(['declaration'])"
                >
                  <i class="fe fe-layers" />{{ $t("menu.declaration") }}
                  <a-form-item>
                    <a-tree
                      v-model="updateData.access.declaration"
                      checkable
                      :default-expand-all="true"
                      @select="onSelect"
                    >
                      <a-tree-node key="all" :title="$t('menu.declaration')">
                        <a-tree-node
                          key="etat_impayesA"
                          :title="$t('menu.etatImpayesActivites')"
                        />
                        <a-tree-node
                          key="etat_impayesS"
                          :title="$t('menu.etatImpayesScolarite')"
                        />
                        <a-tree-node
                          key="declarationTVA"
                          :title="$t('menu.declarationTva')"
                        />

                        <a-tree-node
                          key="chiffre_affaire"
                          :title="$t('menu.chiffreAffaires')"
                        />

                        <a-tree-node
                          key="chiffre_affaireS"
                          :title="$t('menu.chiffreAffairesScolarite')"
                        />
                      </a-tree-node>
                    </a-tree>
                  </a-form-item>
                </div>
              </div>
              <div class="form-actions row">
                <div class="col-md-2 offset-md-7">
                  <a-button
                    key="back"
                    @click="handleCancel"
                    :disabled="loadingAddAccess"
                  >
                    Annuler
                  </a-button>
                </div>
                <div class="col-md-2 offset-md-1">
                  <a-button
                    type="primary"
                    htmlType="submit"
                    :loading="loadingAddAccess"
                    :disabled="loadingAddAccess"
                  >
                    {{ $t("action.modifier") }}
                  </a-button>
                </div>
              </div>
            </a-form>
          </a-modal>
        </div>
      </template>
      <a-table
        :data-source="data"
        :columns="columns"
        :loading="tableLoading"
        :scroll="{ x: 'max-content' }"
        :pagination="true"
        rowKey="_id"
      >
        <template slot-scope="text" slot="designation">{{ text }}</template>
        <template
          v-for="col in [
            'all',
            //'home',
            //'liste_eleves',
            //'bulletin',
            'assiduite',
            'actualite',
            'calendar',
          ]"
          :slot="col"
          slot-scope="text, record"
        >
          {{ record.access[col] ? $t("all.oui") : $t("all.non") }}
        </template>
        <div slot="expandedRowRender" slot-scope="record">
          <div class="row">
            <div class="col-md-1" v-if="verifyEdManagerAccess(['sms'])">
              <a-tree
                :default-expand-all="true"
                v-if="record.access.sms.length > 0"
              >
                <a-tree-node key="all" :title="$t('menu.sms')">
                  <a-tree-node
                    key="send"
                    :title="$t('menu.envoyerSms')"
                    v-show="record.access.sms.includes('send')"
                  />
                  <a-tree-node
                    key="history"
                    :title="$t('menu.historiqueSms')"
                    v-show="record.access.sms.includes('history')"
                  />
                </a-tree-node>
              </a-tree>
            </div>
          </div>
          <div class="row">
            <div class="col-md-1" v-if="verifyEdManagerAccess(['chat'])">
              <a-tree
                :default-expand-all="true"
                v-if="record.access.chat.length > 0"
              >
                <a-tree-node key="all" :title="$t('menu.chat')">
                  <a-tree-node
                    key="chat"
                    :title="$t('menu.chat')"
                    v-show="record.access.chat.includes('chat')"
                  />
                  <a-tree-node
                    key="parent_details"
                    :title="$t('chat.parentDetails')"
                    v-show="record.access.chat.includes('parent_details')"
                  />
                  <a-tree-node
                    key="payment_status"
                    :title="$t('paymentStatus.paymentStatus')"
                    v-show="record.access.chat.includes('payment_status')"
                  />
                </a-tree-node>
              </a-tree>
            </div>
            <div class="col-md-1" v-if="verifyEdManagerAccess(['ptchat'])">
              <a-tree
                :default-expand-all="true"
                v-if="record.access.supervision.length > 0"
              >
                <a-tree-node key="all" :title="$t('menu.supervision')">
                  <a-tree-node
                    key="ptchat"
                    :title="$t('menu.ptchat')"
                    v-show="record.access.supervision.includes('ptchat')"
                  />
                </a-tree-node>
              </a-tree>
            </div>
            <a-divider
              orientation="left"
              v-if="record.access.parametrage.length > 0"
              >{{ $t("autorisation.parametrage") }}</a-divider
            >

            <div class="col-md-1">
              <a-tree
                :default-expand-all="true"
                v-if="record.access.parametrage.length > 0"
              >
                <a-tree-node key="all" :title="$t('autorisation.parametrage')">
                  <a-tree-node
                    key="etablissement"
                    :title="$t('menu.etablissement')"
                    v-show="record.access.parametrage.includes('etablissement')"
                    v-if="verifyEdManagerAccess(['parametrageEtablissement'])"
                  />
                  <a-tree-node
                    key="comptabilite"
                    :title="$t('menu.comptabilite')"
                    v-show="record.access.parametrage.includes('comptabilite')"
                    v-if="verifyEdManagerAccess(['parametrageComptabilite'])"
                  />
                  <a-tree-node
                    key="scolarite"
                    :title="$t('menu.scolarite')"
                    v-show="record.access.parametrage.includes('scolarite')"
                    v-if="verifyEdManagerAccess(['parametrageScolarite'])"
                  />
                  <a-tree-node
                    key="bulletin"
                    :title="$t('menu.bulletin')"
                    v-show="record.access.parametrage.includes('bulletin')"
                    v-if="verifyEdManagerAccess(['bulletin'])"
                  />
                  <a-tree-node
                    key="sms"
                    :title="$t('menu.sms')"
                    v-show="record.access.parametrage.includes('sms')"
                    v-if="verifyEdManagerAccess(['sms'])"
                  />
                  <a-tree-node
                    key="smart_schedule"
                    :title="$t('menu.smartSchedule')"
                    v-show="
                      record.access.parametrage.includes('smart_schedule')
                    "
                    v-if="verifyEdManagerAccess(['smartSchedule'])"
                  />
                </a-tree-node>
              </a-tree>
            </div>
            <div class="col" v-if="verifyEdManagerAccess(['adminstration'])">
              <a-tree
                v-model="record.access.adminstration"
                :default-expand-all="true"
                v-if="record.access.adminstration.length > 0"
              >
                <a-tree-node key="all" :title="$t('menu.administration')">
                  <i slot="icon" class="fa fa-expeditedssl" />
                  <a-tree-node
                    key="autorisation"
                    :title="$t('menu.utilisateur')"
                    v-show="
                      record.access.adminstration.includes('autorisation')
                    "
                  />
                  <a-tree-node
                    key="utilisateur"
                    :title="$t('menu.utilisateur')"
                    v-show="record.access.adminstration.includes('utilisateur')"
                  />
                </a-tree-node>
              </a-tree>
            </div>
            <a-divider orientation="left">{{ $t("menu.scolarite") }}</a-divider>
            <div class="col-md-1" v-if="verifyEdManagerAccess(['studentList'])">
              <a-tree
                v-model="record.access.liste_eleves"
                :default-expand-all="true"
                v-if="
                  record.access.liste_eleves &&
                  record.access.liste_eleves.length > 0
                "
              >
                <a-tree-node key="all" :title="$t('menu.listeEleves')">
                  <a-tree-node
                    key="liste_eleves"
                    :title="$t('menu.listeEleves')"
                    v-show="record.access.liste_eleves.includes('liste_eleves')"
                  />
                  <a-tree-node
                    key="annulation_eleve"
                    :title="$t('profil.annulationEleve')"
                    v-show="
                      record.access.liste_eleves.includes('annulation_eleve')
                    "
                  />
                  <a-tree-node
                    key="suppression_eleve"
                    v-show="
                      record.access.liste_eleves.includes('suppression_eleve')
                    "
                    :title="$t('profil.deleteEleve')"
                  />
                  <a-tree-node
                    key="profil_information"
                    v-show="
                      record.access.liste_eleves.includes('profil_information')
                    "
                    :title="$t('profil.informations')"
                  />
                  <a-tree-node
                    key="paiement_scolarite"
                    v-show="
                      record.access.liste_eleves.includes('paiement_scolarite')
                    "
                    :title="$t('profil.paiementScol')"
                  />
                  <a-tree-node
                    key="paiement_activite"
                    v-show="
                      record.access.liste_eleves.includes('paiement_activite')
                    "
                    :title="$t('profil.paiementAct')"
                  />
                  <a-tree-node
                    key="liste_reglement"
                    v-show="
                      record.access.liste_eleves.includes('liste_reglement')
                    "
                    :title="$t('profil.listeReglements')"
                  />
                  <a-tree-node
                    key="qr_code"
                    v-show="record.access.liste_eleves.includes('qr_code')"
                    :title="$t('profil.qrCode')"
                  />
                  <a-tree-node
                    key="bulletin"
                    v-show="record.access.liste_eleves.includes('bulletin')"
                    :title="$t('bulletin.bulletin')"
                  />
                  <a-tree-node
                    key="assiduite"
                    v-show="record.access.liste_eleves.includes('assiduite')"
                    :title="$t('menu.assiduite')"
                  />
                </a-tree-node>
              </a-tree>
            </div>
            <div class="col-md-1" v-if="verifyEdManagerAccess(['bulletin'])">
              <a-tree
                v-model="record.access.bulletin"
                :default-expand-all="true"
                v-if="
                  record.access.bulletin && record.access.bulletin.length > 0
                "
              >
                <a-tree-node key="all" :title="$t('menu.bulletin')">
                  <a-tree-node
                    key="pedagogic"
                    :title="$t('bulletin.bulletinPedagogique')"
                    v-show="record.access.bulletin.includes('pedagogic')"
                  />
                  <a-tree-node
                    key="pilote"
                    :title="$t('bulletin.bulletinSpecifique')"
                    v-show="record.access.bulletin.includes('pilote')"
                  />
                  <a-tree-node
                    key="specific"
                    :title="$t('bulletin.bulletinVerySpecifique')"
                    v-show="record.access.bulletin.includes('specific')"
                  />
                  <a-tree-node
                    key="statistique"
                    :title="$t('menu.bulletinStatistique')"
                    v-show="record.access.bulletin.includes('statistique')"
                  />
                </a-tree-node>
              </a-tree>
            </div>
            <div
              class="col-md-1"
              v-if="verifyEdManagerAccess(['activites', 'cantine'])"
            >
              <a-tree
                :default-expand-all="true"
                v-if="record.access.activite.length > 0"
              >
                <a-tree-node key="all" :title="$t('accueil.activite')">
                  <a-tree-node
                    key="inscription"
                    :title="$t('inscription.inscription')"
                    v-show="record.access.activite.includes('inscription')"
                    v-if="verifyEdManagerAccess(['activites'])"
                  />

                  <a-tree-node
                    key="etat_paiement"
                    :title="$t('menu.etatPaiements')"
                    v-show="record.access.activite.includes('etat_paiement')"
                    v-if="verifyEdManagerAccess(['activites'])"
                  />
                  <a-tree-node
                    key="cantine"
                    :title="$t('menu.cantine')"
                    v-show="record.access.activite.includes('cantine')"
                    v-if="verifyEdManagerAccess(['cantine'])"
                  />
                </a-tree-node>
              </a-tree>
            </div>
            <div
              class="col-md-1"
              v-if="verifyEdManagerAccess(['scolarite', 'punishments'])"
            >
              <a-tree
                :default-expand-all="true"
                v-if="record.access.scolarite.length > 0"
              >
                <a-tree-node key="all" :title="$t('menu.scolarite')">
                  <a-tree-node
                    key="inscription"
                    :title="$t('inscription.inscription')"
                    v-show="record.access.scolarite.includes('inscription')"
                  />
                  <a-tree-node
                    key="etat_paiement"
                    :title="$t('menu.etatPaiements')"
                    v-show="record.access.scolarite.includes('etat_paiement')"
                  />
                  <a-tree-node
                    key="punition"
                    :title="$t('punition.punition')"
                    v-if="verifyEdManagerAccess(['punishments'])"
                    v-show="record.access.scolarite.includes('punition')"
                  />
                </a-tree-node>
              </a-tree>
            </div>
            <div class="col-md-1" v-if="verifyEdManagerAccess(['personnel'])">
              <a-tree
                :default-expand-all="true"
                v-if="record.access.personnel.length > 0"
              >
                <a-tree-node key="all" :title="$t('etatPaiement.personnel')">
                  <a-tree-node
                    key="personnel"
                    :title="$t('etatPaiement.personnel')"
                    v-show="record.access.personnel.includes('personnel')"
                  />
                  <a-tree-node
                    key="enseignant"
                    :title="$t('emploi.enseignant')"
                    v-show="record.access.personnel.includes('enseignant')"
                  />
                  <a-tree-node
                    key="etat_paiement"
                    :title="$t('menu.etatPaiements')"
                    v-show="record.access.personnel.includes('etat_paiement')"
                  />
                  <a-tree-node
                    key="viremenet_bancaire"
                    :title="$t('menu.virementBancaire')"
                    v-show="
                      record.access.personnel.includes('viremenet_bancaire')
                    "
                  />
                </a-tree-node>
              </a-tree>
            </div>
            <div
              class="col"
              v-if="verifyEdManagerAccess(['emplois', 'smartSchedule'])"
            >
              <a-tree
                :default-expand-all="true"
                v-if="record.access.emplois.length > 0"
              >
                <a-tree-node key="all" :title="$t('emploi.emplois')">
                  <a-tree-node
                    key="eleves"
                    :title="$t('menu.eleves')"
                    v-show="record.access.emplois.includes('eleves')"
                    v-if="verifyEdManagerAccess(['emplois'])"
                  />
                  <a-tree-node
                    key="enseignant"
                    :title="$t('emploi.enseignant')"
                    v-show="record.access.emplois.includes('enseignant')"
                    v-if="verifyEdManagerAccess(['emplois'])"
                  />
                  <a-tree-node
                    key="smart_schedules"
                    :title="$t('smartSchedule.smartSchedules')"
                    v-show="record.access.emplois.includes('smart_schedules')"
                    v-if="verifyEdManagerAccess(['smartSchedule'])"
                  />
                  <a-tree-node
                    key="teacher_dispo"
                    :title="$t('menu.teacherAvailability')"
                    v-show="record.access.emplois.includes('teacher_dispo')"
                    v-if="verifyEdManagerAccess(['smartSchedule'])"
                  />
                </a-tree-node>
              </a-tree>
            </div>
            <a-divider orientation="left">{{
              $t("comptabilite.comptabilite")
            }}</a-divider>
            <div
              class="col-md-2"
              v-if="verifyEdManagerAccess(['comptabilite'])"
            >
              <a-tree
                :default-expand-all="true"
                v-if="record.access.comptabilite.length > 0"
              >
                <a-tree-node key="all" :title="$t('menu.comptabilite')">
                  <a-tree-node
                    key="etat_recettes"
                    :title="$t('menu.etatRecettes')"
                    v-show="
                      record.access.comptabilite.includes('etat_recettes')
                    "
                  />
                  <a-tree-node
                    key="avance"
                    :title="$t('menu.caisseAvance')"
                    v-show="record.access.comptabilite.includes('avance')"
                  />
                  <a-tree-node
                    key="journale_caisse"
                    :title="$t('menu.journaleCaisse')"
                    v-show="
                      record.access.comptabilite.includes('journale_caisse')
                    "
                  />
                  <a-tree-node
                    key="depenses"
                    :title="$t('menu.depenses')"
                    v-show="record.access.comptabilite.includes('depenses')"
                  />

                  <a-tree-node
                    key="etat_impayes"
                    :title="$t('menu.etatImpayes')"
                    v-show="record.access.comptabilite.includes('etat_impayes')"
                  />

                  <a-tree-node
                    key="liste_reglement"
                    :title="$t('menu.listeReglement')"
                    v-show="
                      record.access.comptabilite.includes('liste_reglement')
                    "
                  />
                  <a-tree-node
                    key="reglement_annule"
                    :title="$t('menu.reglementsAnnules')"
                    v-show="
                      record.access.comptabilite.includes('reglement_annule')
                    "
                  />
                </a-tree-node>
              </a-tree>
            </div>
            <div class="col" v-if="verifyEdManagerAccess(['declaration'])">
              <a-tree
                :default-expand-all="true"
                v-if="record.access.declaration.length > 0"
              >
                <a-tree-node key="all" :title="$t('menu.declaration')">
                  <a-tree-node
                    key="etat_impayesA"
                    :title="$t('menu.etatImpayesActivites')"
                    v-show="record.access.declaration.includes('etat_impayesA')"
                  />
                  <a-tree-node
                    key="etat_impayesS"
                    :title="$t('menu.etatImpayesScolarite')"
                    v-show="record.access.declaration.includes('etat_impayesS')"
                  />
                  <a-tree-node
                    key="declarationTVA"
                    :title="$t('menu.declarationTva')"
                    v-show="
                      record.access.declaration.includes('declarationTVA')
                    "
                  />

                  <a-tree-node
                    key="chiffre_affaire"
                    :title="$t('menu.chiffreAffaires')"
                    v-show="
                      record.access.declaration.includes('chiffre_affaire')
                    "
                  />

                  <a-tree-node
                    key="chiffre_affaireS"
                    :title="$t('menu.chiffreAffairesScolarite')"
                    v-show="
                      record.access.declaration.includes('chiffre_affaireS')
                    "
                  />
                </a-tree-node>
              </a-tree>
            </div>
          </div>
        </div>
        <template slot="operation2" slot-scope="text, record">
          <a-popconfirm
            :disabled="record.role === 'admin'"
            :title="$t('all.sureToDelete')"
            @confirm="() => onDelete(record._id)"
          >
            <a-button type="danger" :disabled="record.role === 'admin'">
              <a-icon type="delete" href="javascript:;" />{{
                $t("action.supprimer")
              }}
            </a-button>
          </a-popconfirm>
        </template>
        <template slot="operation" slot-scope="text, record">
          <div class="editable-row-operations">
            <span>
              <a-button
                type="primary"
                :disabled="record.role === 'admin'"
                @click="() => edit(record)"
              >
                <a-icon type="edit" @click="() => edit(record)" />{{
                  $t("action.modifier")
                }}
              </a-button>
            </span>
          </div>
        </template>
        <div
          slot="filterDropdown"
          slot-scope="{
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            column,
          }"
          style="padding: 8px"
        >
          <a-input
            v-ant-ref="(c) => (searchInput = c)"
            :placeholder="`${$t('personnel.chercher')} ${column.title}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block"
            @change="
              (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
            "
            @pressEnter="
              () => handleSearch(selectedKeys, confirm, column.dataIndex)
            "
          />
          <a-button
            type="primary"
            icon="search"
            size="small"
            style="width: 90px; margin-right: 8px"
            @click="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
            >{{ $t("paiement.chercher") }}</a-button
          >
          <a-button
            size="small"
            style="width: 90px"
            @click="() => handleReset(clearFilters)"
            >{{ $t("action.reinitialiser") }}</a-button
          >
        </div>
        <a-icon
          slot="filterIcon"
          slot-scope="filtered"
          type="search"
          :style="{ color: filtered ? '#108ee9' : undefined }"
        />
        <template slot="customRender" slot-scope="text, record, index, column">
          <span v-if="searchText && searchedColumn === column.dataIndex">
            <template
              v-for="(fragment, i) in text
                .toString()
                .split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
            >
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
                >{{ fragment }}</mark
              >
              <template v-else>{{ fragment }}</template>
            </template>
          </span>
          <template v-else>{{ text }}</template>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from "vue";
import VueRouter from "vue-router";
import apiClient from "@/services/axios";
import { PageHeader, Descriptions } from "ant-design-vue";
import { mapState } from "vuex";
const allChecked = {
  calendar: true,
  parametrage: [
    "all",
    "etablissement",
    "comptabilite",
    "scolarite",
    "bulletin",
    "sms",
    "smart_schedule",
  ],
  adminstration: ["all", "autorisation", "utilisateur"],
  activite: ["all", "inscription", "etat_paiement", "cantine"],
  scolarite: ["all", "inscription", "etat_paiement", "punition"],
  personnel: [
    "all",
    "personnel",
    "enseignant",
    "etat_paiement",
    "viremenet_bancaire",
  ],
  emplois: ["all", "eleves", "enseignant", "smart_schedules", "teacher_dispo"],
  declaration: [
    "all",
    "etat_impayesA",
    "etat_impayesS",
    "declarationTVA",
    "chiffre_affaire",
    "chiffre_affaireS",
  ],
  comptabilite: [
    "all",
    "etat_recettes",
    "avance",
    "journale_caisse",
    "depenses",
    "etat_impayes",
    "liste_reglement",
    "reglement_annule",
  ],
  sms: ["send", "history"],
  home: ["all", "usersStats", "statstiqueGeneral"],
  chat: ["all", "chat", "parent_details", "payment_status"],
  supervision: ["all", "ptchat"],
  actualite: true,
  liste_eleves: [
    "all",
    "liste_eleves",
    "annulation_eleve",
    "suppression_eleve",
    "profil_information",
    "paiement_scolarite",
    "paiement_activite",
    "liste_reglement",
    "qr_code",
    "bulletin",
    "assiduite",
  ],
  bulletin: ["all", "pedagogic", "pilote", "specific", "statistique"],
  assiduite: true,
  all: true,
};
export default {
  computed: {
    ...mapState(["settings", "user"]),
    schoolDetails() {
      return (
        this.user.building.filter(
          (el) => el.dbName == this.settings.activeBuilding
        )[0] || {}
      );
    },
  },

  beforeMount() {
    this.form = this.$form.createForm(this, { name: "addAccessForm" });
    this.updateForm = this.$form.createForm(this, { name: "updateAccessForm" });
  },
  async created() {
    this.tableLoading = true;

    this.columns = this.columns.filter((column) => {
      if (column.buildingAccess) {
        return this.verifyEdManagerAccess([column.buildingAccess]);
      }
      return true;
    });

    //only get user roles that belongs to the buidling
    await apiClient.get("/autorisation/buildingRoles").then((res) => {
      console.info("buildingRoles before: ", res.data);
      // for old data (home was a boolean)
      res.data.forEach((role) => {
        if (role.access.home === true || role.access.home == ["true"])
          role.access.home = [...allChecked.home];
        else if (
          role.access.home === false ||
          (Array.isArray(role.access.home) &&
            role.access.home.includes("false"))
        )
          role.access.home = [];
        return role;
      });
      // for old data (liste_eleves was a boolean)
      res.data.forEach((role) => {
        if (role.access.liste_eleves === true)
          role.access.liste_eleves = [...allChecked.liste_eleves];
        else if (role.access.liste_eleves === false)
          role.access.liste_eleves = [];
        return role;
      });
      // for old data (bulletin was a boolean)
      res.data.forEach((role) => {
        if (role.access.bulletin === true)
          role.access.bulletin = [...allChecked.bulletin];
        else if (role.access.bulletin === false) role.access.bulletin = [];
        return role;
      });
      this.data = res.data;
      console.info("buildingRoles: ", this.data);
    });
    this.tableLoading = false;
  },
  data() {
    return {
      data: [],
      tableLoading: false,
      updateVisible: false,
      updateData: {
        _id: "",
        role: "",
        access: {},
      },
      loading: false,
      visible: false,
      loadingAddAccess: false,
      form: this.$form.createForm(this),
      updateForm: this.$form.createForm(this, { name: "updateAccessForm" }),
      columns: [
        {
          title: this.$t("autorisation.designation"),
          dataIndex: "role",
          key: "designation",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "designation",
          },
          onFilter: (value, record) =>
            record.role.toString().toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("autorisation.compteAdmin"),
          dataIndex: "access.all",
          key: "all",
          scopedSlots: {
            customRender: "all",
          },
          filters: [
            { text: this.$t("all.oui"), value: true },
            { text: this.$t("all.non"), value: false },
          ],
          onFilter: (value, record) => {
            if (value) return record.access.all;
            else return !record.access.all;
          },
        },
        //{
        //  title: this.$t("autorisation.accueil"),
        //  dataIndex: "access.home",
        //  key: "home",
        //  scopedSlots: {
        //    customRender: "home",
        //  },
        //  filters: [
        //    { text: this.$t("all.oui"), value: true },
        //    { text: this.$t("all.non"), value: false },
        //  ],
        //  onFilter: (value, record) => {
        //    if (value) return record.access.home;
        //    else return !record.access.home;
        //  },
        //  buildingAccess: "statstiqueGeneral",
        //},
        // {
        //   title: this.$t("autorisation.listeEleves"),
        //   dataIndex: "access.liste_eleves",
        //   key: "liste_eleves",
        //   scopedSlots: {
        //     filterDropdown: "filterDropdown",
        //     filterIcon: "filterIcon",
        //     customRender: "liste_eleves",
        //   },
        // },
        // {
        //   title: this.$t("bulletin.bulletin"),
        //   dataIndex: "access.bulletin",
        //   key: "bulletin",
        //   scopedSlots: {
        //     filterDropdown: "filterDropdown",
        //     filterIcon: "filterIcon",
        //     customRender: "bulletin",
        //   },
        // },
        {
          title: this.$t("menu.assiduite"),
          dataIndex: "access.assiduite",
          key: "assiduite",
          scopedSlots: {
            customRender: "assiduite",
          },
          filters: [
            { text: this.$t("all.oui"), value: true },
            { text: this.$t("all.non"), value: false },
          ],
          onFilter: (value, record) => {
            if (value) return record.access.assiduite;
            else return !record.access.assiduite;
          },
          buildingAccess: "attendance",
        },
        {
          title: this.$t("menu.actulite"),
          dataIndex: "access.actualite",
          key: "actualite",
          scopedSlots: {
            customRender: "actualite",
          },
          filters: [
            { text: this.$t("all.oui"), value: true },
            { text: this.$t("all.non"), value: false },
          ],
          onFilter: (value, record) => {
            if (value) return record.access.actualite;
            else return !record.access.actualite;
          },
          buildingAccess: "news",
        },
        {
          title: this.$t("menu.calender"),
          dataIndex: "access.calendar",
          key: "calendar",
          scopedSlots: {
            customRender: "calendar",
          },
          filters: [
            { text: this.$t("all.oui"), value: true },
            { text: this.$t("all.non"), value: false },
          ],
          onFilter: (value, record) => {
            if (value) return record.access.calendar;
            else return !record.access.calendar;
          },
          buildingAccess: "calender",
        },
        {
          title: this.$t("action.modifier"),
          dataIndex: "operation",
          scopedSlots: { customRender: "operation" },
        },
        {
          title: this.$t("action.supprimer"),
          dataIndex: "operation2",
          scopedSlots: { customRender: "operation2" },
        },
      ],
      autoExpandParent: true,
      checkedKeys: {
        //home: false,
        actualite: false,
        calendar: false,
        // liste_eleves: false,
        // bulletin: false,
        assiduite: false,
        all: false,
      },
      selectedKeys: [],
    };
  },
  watch: {
    checkedKeys: {
      handler(val, old) {
        //   console.log("val", JSON.stringify(this.checkedKeys));
        //  console.log("allChecked", JSON.stringify(allChecked));
        console.log(old.all, val.all);
        //   console.log("val", _.isEqual(allChecked, val));

        if (!_.isEqual(allChecked, val) && val.all === true) {
          this.checkedKeys.all = false;
        }
      },
      deep: true,
    },
  },
  methods: {
    handleAdminChecked(value, e) {
      if (value) this.checkedKeys = { ...allChecked };
      else
        this.checkedKeys = {
          //home: false,
          actualite: false,
          calendar: false,
          // liste_eleves: false,
          // bulletin: false,
          assiduite: false,
          all: false,
        };
    },
    handleAdminCheckedUpdate(value, e) {
      if (value) this.updateData.access = { ...allChecked };
      else
        this.updateData.access = {
          //home: false,
          actualite: false,
          calendar: false,
          // liste_eleves: false,
          // bulletin: false,
          assiduite: false,
          all: false,
        };
    },
    onCheck(checkedKeys) {
      console.log("onCheck", checkedKeys);
      this.checkedKeys = checkedKeys;
    },
    onSelect(selectedKeys, info) {
      console.log("onSelect", info);
      this.selectedKeys = selectedKeys;
    },
    addAccess(e) {
      this.loadingAddAccess = true;
      e.preventDefault();
      this.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          values.building = this.settings.activeBuildingId;
          console.log(this.checkedKeys);
          console.log(values);
          values.access = this.checkedKeys;
          apiClient
            .put("/roles", { data: values })
            .then((res) => {
              this.$message.success(this.$t("success.compte"));
              this.data.push(res.data);
              this.form.resetFields();
              this.visible = false;
            })
            .catch((e) => {
              console.error(e);
              this.$message.error(this.$t("error.ajout"));
            })
            .finally(() => (this.loadingAddAccess = false));
        } else {
          this.loadingAddAccess = false;
        }
      });
    },
    updateAccess(e) {
      this.loadingAddAccess = true;

      e.preventDefault();
      this.updateForm.validateFieldsAndScroll((err, values) => {
        if (!err) {
          const id = this.updateData._id;
          console.log(this.updateData);
          apiClient
            .patch(`/roles/${id}`, { data: { access: this.updateData.access } })
            .then((res) => {
              this.$socket.client.emit("updateAccess", {
                accessId: id,
              });
              this.$message.success(this.$t("success.autorisationMSA"));
              this.updateVisible = false;
            })
            .catch(() =>
              this.$message.error(this.$t("error.autorisationNonMSA"))
            )
            .finally(() => (this.loadingAddAccess = false));
        }
      });
    },
    showModal() {
      this.visible = true;
    },
    handleOk(e) {},
    handleCancel(e) {
      this.visible = false;
      this.updateVisible = false;
    },
    onDelete(id) {
      apiClient
        .patch(`/roles/${id}`, {
          data: {
            status: "inactive",
          },
        })
        .then((res) => {
          this.$message.success(this.$t("success.autorisationSupp"));
          this.data = this.data.filter((elem) => elem._id != id);
        })
        .catch(() => this.$message.error(this.$t("error.autorisationNonSupp")));
    },
    edit(record) {
      this.updateData = record;
      console.log(this.updateData);
      this.updateVisible = true;
    },
    save(key) {},
    cancel(key) {},
    verifyEdManagerAccess(features) {
      for (const feature of features) {
        if (this.schoolDetails.access.edManager[feature]) return true;
      }
      return false;
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      this.searchText = selectedKeys[0];
      this.searchedColumn = dataIndex;
    },

    handleReset(clearFilters) {
      clearFilters();
      this.searchText = "";
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },

    filterOptionFilter(inputValue, option) {
      return option.description.indexOf(inputValue) > -1;
    },
    handleChange(targetKeys, direction, moveKeys) {
      this.targetKeys = targetKeys;
    },
  },
};
</script>
